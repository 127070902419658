
export const groupBy = (list, field, arr = false) => {

    let data = list.reduce(function (rv, x) {
        (rv[x[field]] = rv[x[field]] || []).push(x);
        return rv;
    }, {});

    if (arr) {
        data = Object.values(data)
    }

    return data
};

export const groupByKey = (list, field,) => {

    let data = list.reduce(function (rv, x) {
        rv[x[field]] = x;
        return rv;
    }, {});
    return data
};

export const makeSlug = (name) => {
    return makePlainText(name, '-')
}


export const makeKey = (name) => {
    return makePlainText(name, '_')
}

export const makePlainText = (name, delimiter) => {
    if(!name) {
        name = ""
    }
    return name.trim().toLowerCase()
        .replace(/[ /&-]/g, delimiter) // replacing spaces, &, and - with the specified delimiter
        .replace(/[^\w]+/g, '') // removing special chars
        .replace(/[ /$@!;()*&%^/\\:{}-]+/g, delimiter) // replacing specified special chars and - with the delimiter
        .replace(/(_)\1+/ig, (str, match) => { // removing duplicate consecutive '_'
            return match[0];
        });
}


export const arrayUniqueByKey = (array, key) => {
    return [...new Map(array.map(item =>
        [item[key], item])).values()];
}
